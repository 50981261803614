import React, { useState } from 'react'
import { X } from 'lucide-react'
import logo from '../images/logo.png'

export default function DeleteAccount() {
  const [email, setEmail] = useState('')
  const [reason, setReason] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    
    // Here you would typically send the data to your backend
    // For this example, we'll just simulate a successful submission
    await new Promise(resolve => setTimeout(resolve, 1000))
    
    setIsSubmitted(true)
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {/* Header */}
      <header className="w-full bg-white shadow-sm py-4">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center">
            <img src={logo} alt="Kapimej" className="h-16 w-auto" />
            <h1 className="text-2xl font-bold text-gray-800">Delete Account</h1>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="max-w-md mx-auto bg-white rounded-lg shadow-md overflow-hidden">
          {isSubmitted ? (
            <div className="p-6 text-center">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Request Submitted</h2>
              <p className="text-gray-600">
                Your account deletion request has been sent to our support team. 
                We'll process your request and contact you via email if we need any additional information.
              </p>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="p-6 space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  placeholder="your@email.com"
                />
              </div>
              <div>
                <label htmlFor="reason" className="block text-sm font-medium text-gray-700">
                  Reason for Deletion
                </label>
                <textarea
                  id="reason"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  required
                  rows={4}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  placeholder="Please tell us why you want to delete your account..."
                />
              </div>
              <div className="text-sm text-gray-600">
                <p>Your request will be sent to support@kapimej.app for processing.</p>
              </div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Submit Deletion Request
              </button>
            </form>
          )}
        </div>
      </main>

      {/* Footer */}
      <footer className="w-full bg-gray-900 text-white py-4">
        <div className="container mx-auto px-4 text-center">
          <p className="text-sm">© {new Date().getFullYear()} Kapimej. All rights reserved.</p>
        </div>
      </footer>
    </div>
  )
}