import { useState } from 'react'

export default function FAQPage() {
  const [searchTerm, setSearchTerm] = useState('')
  const [openItems, setOpenItems] = useState<number[]>([])

  const faqItems = [
    {
      question: "What is Kapimej?",
      answer: "Kapimej is an app that makes traveling easier for Armenians. It lets you stay connected to the internet while you're abroad using eSIM technology, so you don't have to do the hassle of visiting local telecom shops."
    },
    {
      question: "What is an eSIM?",
      answer: "An eSIM is a digital version of a SIM card that's built right into your phone or device. It does everything a regular SIM card does, but you don’t need a physical card anymore. No more worrying about losing or damaging your SIM, and no need to use paperclips to open your phone to swap cards. It's simple and hassle-free!"
    },
    {
      question: "What are the benefits of using Kapimej?",
      answer: "With Kapimej, you can enjoy fast and reliable internet while traveling abroad, along with customer support in Armenian if needed. Plus, with Kapiflex, you have extra peace of mind. If your flight gets canceled by the airline, you can get a full refund for your eSIM."
    },
    {
      question: "How to buy an eSIM?",
      answer: "You can buy an eSIM from the Kapimej app. Just choose the plan you want, pay for it, and then activate it on your device. It's easy and fast!"
    },
    {
      question: "How to activate an eSIM?",
      answer: "After you buy an eSIM, go to the eSIMs tab in the app, and click on the eSIM you want to activate. Then, follow the instructions to activate it on your device."
    },
    {
      question: "When should I activate my eSIM?",
      answer: "You should activate your eSIM before you leave for your trip. This way, you'll have internet access as soon as you land in your destination. The plan will start once you get signal from the network."
    },
    {
      question: "Can I receive phone calls or SMS?",
      answer: "No, Kapimej eSIMs are only for data. You can't receive phone calls or SMS with them."
    },
    {
      question: "Can I use my eSIM alongside my regular SIM card?",
      answer: "Yes, you can use your eSIM alongside your regular SIM card. This allows you to keep your local number active while using Kapimej for data connectivity abroad."
    },
    {
      question: "Can I use hotspots with my eSIM?",
      answer: "Yes, you can use hotspots with your eSIM."
    },
    {
      question: "Can I use one eSIM for multiple devices?",
      answer: "No, you can't use one eSIM for multiple devices. Each eSIM is designed to work with one device at a time. Once you activate an eSIM, it can't be used on another device.",
    },
    {
      question: "Can I get a refund for my eSIM?",
      answer: "If you didn't activate your eSIM, you can get a refund. If you activated your eSIM, you can't get a refund. If your flight gets canceled by the airline, you can get a full refund for your eSIM if you purchased Kapiflex along with it.",
    },
    {
      question: "How to delete my account?",
      answer: "Contact us at support@kapimej.com and we will help you delete your account."
    },
    {
      question: "How to contact support?",
      answer: "You can contact us at support@kapimej.com. We are available 24/7 to help you with any questions or issues you might have."
    },
   
  ]

  const toggleItem = (index: number) => {
    setOpenItems(prevOpenItems =>
      prevOpenItems.includes(index)
        ? prevOpenItems.filter(item => item !== index)
        : [...prevOpenItems, index]
    )
  }

  const filteredFAQs = faqItems.filter(item =>
    item.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.answer.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-extrabold text-gray-900 text-center mb-8">
          Frequently Asked Questions
        </h1>
        <div className="mb-6">
          <input
            type="text"
            placeholder="Search FAQs..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="space-y-4">
          {filteredFAQs.map((item, index) => (
            <div key={index} className="bg-white overflow-hidden shadow rounded-lg">
              <button
                className="w-full px-4 py-5 sm:p-6 text-left flex justify-between items-center focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={() => toggleItem(index)}
              >
                <span className="text-lg font-medium text-gray-900">{item.question}</span>
                <svg
                  className={`h-5 w-5 text-gray-500 transform ${openItems.includes(index) ? 'rotate-180' : ''}`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
              {openItems.includes(index) && (
                <div className="px-4 pb-5 sm:px-6 sm:pb-6">
                  <p className="text-gray-700">{item.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
        {filteredFAQs.length === 0 && (
          <p className="text-center text-gray-500 mt-8">
            No FAQs found matching your search term. Please try a different query.
          </p>
        )}
      </div>
    </div>
  )
}